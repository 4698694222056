import React from 'react';
import Form from 'react-bootstrap/Form';
import { func, string } from 'prop-types';
import './style.css';

const SearchInput = (props) => {
	const { onChange, value } = props;
	return (
		<div className={'search_input'}>
			<Form.Control
				type={'input'}
				onChange={(e) => onChange(e.target.value)}
				value={value}
			/>
		</div>
	);
};

SearchInput.propTypes = {
	onChange: func,
	value: string,
};

export default SearchInput;
