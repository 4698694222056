import React from 'react';
import { string } from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import CallIcon from '@mui/icons-material/Call';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import './style.css';

const ServiceIcon = (props) => {
	const { serviceName } = props;
	return (
		<div className={'service_icon'}>
			{
				{
					answers: (
						<OverlayTrigger
							delay="200"
							placement="bottom"
							overlay={
								<Tooltip id="button-tooltip-2">
									<p>Прием показаний</p>
								</Tooltip>
							}
						>
							<CompareArrowsIcon fontSize={'small'} />
						</OverlayTrigger>
					),
					calls: (
						<OverlayTrigger
							delay="200"
							placement="bottom"
							overlay={
								<Tooltip id="button-tooltip-2">
									<p>Обзвон должноков</p>
								</Tooltip>
							}
						>
							<CallIcon fontSize={'small'} />
						</OverlayTrigger>
					),
					shop: (
						<OverlayTrigger
							delay="200"
							placement="bottom"
							overlay={
								<Tooltip id="button-tooltip-2">
									<p>Подтверждение заказов</p>
								</Tooltip>
							}
						>
							<ShoppingCartIcon fontSize={'small'} />
						</OverlayTrigger>
					),
				}[serviceName]
			}
		</div>
	);
};

ServiceIcon.propTypes = {
	serviceName: string,
};

export default ServiceIcon;
