import React from 'react';
import { string } from 'prop-types';
import './style.css';
import { getBalances, getSum } from '../../api/balances';
import { columnsBalances } from '../../utils/columns';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import filterFactory from 'react-bootstrap-table2-filter';

const TableBalances = (props) => {
	const { clientID, startDate, endDate, context } = props;
	const [state, setState] = React.useState([]);
	const [values, setValues] = React.useState(0);
	const [settings, setSettings] = React.useState({
		page: 1,
		sizePerPage: 10,
		filter: {},
		sort: {
			sortField: 'date',
			sortOrder: 'desc',
		},
	});
	const [totalSize, setTotalSize] = React.useState(null);
	const [extension, setExtension] = React.useState('');
	const balanceRoute = context === 'crmTools' ? 'balancesCrm' : 'balances';
	const sumRoute =
		context === 'crmTools' ? 'getConsumptionCrm' : 'getConsumption';

	const customTotal = (from, to, size) => (
		<span>
			Просмотр от {from} до {to} из {size}
		</span>
	);

	const pagination = paginationFactory({
		page: settings.page,
		sizePerPage: settings.sizePerPage,
		totalSize: totalSize,
		lastPageText: '>>',
		firstPageText: '<<',
		nextPageText: '>',
		prePageText: '<',
		showTotal: true,
		alwaysShowAllBtns: true,
		paginationTotalRenderer: customTotal,
		firstPageTitle: 'Первая страница',
		prePageTitle: 'Предыдущая страница',
		nextPageTitle: 'Следующая страница',
		lastPageTitle: 'Последняя страница',
		sizePerPageList: [
			{
				text: '5',
				value: 5,
			},
			{
				text: '10',
				value: 10,
			},
			{
				text: '15',
				value: 15,
			},
			{
				text: '20',
				value: 20,
			},
		],
	});

	const handleData = (data) => {
		if (!data?.length) return [];
		return data.map((item) => {
			const obj = {};
			obj.service = {
				getter: 'Прием показаний',
				autocall: 'Автообзвон должников',
				shop: 'Обработка заказов',
			}[item.service];
			obj.operations = {
				add: 'Пополнение',
				delete: 'Списание',
				add_trial: 'Пополнение (тестовое)',
			}[item.operations];
			obj.date = item.date;
			if (item.service === 'getter') obj.value = `${Math.abs(item.value)} сек.`;
			else obj.value = Math.abs(item.value);
			return obj;
		});
	};

	const onTableChange = async (type, newTable) => {
		setSettings({
			page: newTable.page,
			sizePerPage: newTable.sizePerPage,
			sort: {
				sortField: newTable.sortField,
				sortOrder: newTable.sortOrder,
			},
			filter: newTable.filters,
		});
	};

	React.useEffect(() => {
		if (clientID) {
			getBalances({
				route: balanceRoute,
				clientID,
				startDate,
				endDate,
				...settings,
			}).then(async (res) => {
				setState(res.data);
				setTotalSize(Number(res.headers['x-total-count']));

				if (res.data.length) {
					try {
						console.log(settings, 'settings', clientID, 'clientID');
						const [showValue, extensionValue] = await getSum(
							startDate,
							endDate,
							clientID,
							settings.filter,
							sumRoute
						);
						setValues(showValue);
						setExtension(extensionValue);
					} catch (e) {
						console.log('axios catch', e.message);
						setValues(0);
					}
				} else {
					setValues(0);
				}
			});
		}
	}, [clientID, startDate, endDate, settings]);

	return (
		<ToolkitProvider
			keyField="_id"
			data={handleData(state)}
			columns={columnsBalances}
			bootstrap4
		>
			{(props) => (
				<div>
					<p>{`Сумма: ${values} ${extension}`}</p>
					<BootstrapTable
						remote
						// eslint-disable-next-line
						{...props.baseProps}
						pagination={pagination}
						filter={filterFactory()}
						defaultSorted={[
							{
								dataField: settings.sort.sortField,
								order: settings.sort.sortOrder,
							},
						]}
						onTableChange={onTableChange}
					/>
				</div>
			)}
		</ToolkitProvider>
	);
};

TableBalances.propTypes = {
	clientID: string,
	startDate: string,
	endDate: string,
	context: string,
};

export default TableBalances;
