import React from 'react';
import './style.css';
import { object, string } from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const Tariff = (props) => {
	const { user, service } = props;
	const balance =
		service === 'getter' && user.tariff
			? {
					basic: `Базовый ${Math.ceil(user.balance_getter_basic / 60)}`,
					advanced: `Расширенный ${Math.ceil(
						user.balance_getter_advanced / 60
					)}`,
			  }[user.tariff]
			: service === 'autocall'
			? user.balance_autocall
			: service === 'shop'
			? user.balance_shop
			: service === 'getter' && !user.tariff
			? `Тестовый ${Math.ceil(user.balance_getter_test / 60)}`
			: '';
	const balanceAll = () => {
		const arr = [];
		if (user.balance_getter_test || user.balance_getter_test === 0) {
			arr.push(
				`Прием показаний тестовый ${Math.ceil(user.balance_getter_test / 60)}`
			);
		}
		if (user.balance_getter_basic || user.balance_getter_basic === 0) {
			arr.push(
				`Прием показаний базовый ${Math.ceil(user.balance_getter_basic / 60)}`
			);
		}
		if (user.balance_getter_advanced || user.balance_getter_advanced === 0) {
			arr.push(
				`Прием показаний расширенный ${Math.ceil(
					user.balance_getter_advanced / 60
				)}`
			);
		}
		if (user.balance_autocall || user.balance_autocall === 0) {
			arr.push(`Автообзвон должников ${user.balance_autocall}`);
		}
		if (user.balance_shop || user.balance_shop === 0) {
			arr.push(`Подтверждение заказа ${user.balance_shop}`);
		}
		return arr;
	};
	return (
		<OverlayTrigger
			delay="200"
			placement="bottom"
			overlay={
				<Tooltip id="button-tooltip-2" className={'tariff'}>
					<ul className={'tariff_list'}>
						{balanceAll().map((item, i) => (
							<li key={i}>{item}</li>
						))}
					</ul>
				</Tooltip>
			}
		>
			<span>{balance}</span>
		</OverlayTrigger>
	);
};

Tariff.propTypes = {
	user: object,
	service: string,
};

export default Tariff;
