import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { func, bool, array } from 'prop-types';
import './style.css';

const formatIsoDate = (isoDate) => {
	if (!isoDate) return '';
	return (
		isoDate.slice(8, 10) +
		'.' +
		isoDate.slice(5, 7) +
		'.' +
		isoDate.slice(0, 4) +
		' ' +
		isoDate.slice(11, 19)
	);
};

const MetersListModal = ({ show, close, list }) => {
	const lastRecordDate = list.length ? list[0].date : null;
	const time = formatIsoDate(lastRecordDate);
	return (
		<div>
			<Modal
				show={show}
				onHide={close}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton>
					<h5>Список последних записей приема показаний</h5>
				</Modal.Header>
				<Modal.Body>
					{lastRecordDate ? (
						<p>
							Последний успешный прием показаний был в <b>{time}</b>
						</p>
					) : (
						''
					)}
					<ul>
						{list.map((item) => (
							<li key={item._id}>{formatIsoDate(item.date)}</li>
						))}
					</ul>
				</Modal.Body>
			</Modal>
		</div>
	);
};

MetersListModal.propTypes = {
	show: bool,
	close: func,
	list: array,
};

export default MetersListModal;
