import { selectFilter } from 'react-bootstrap-table2-filter';

export const columns = [
	{
		text: 'Логин',
		dataField: 'login',
		sort: true,
	},
	{
		text: 'Статус SIP подключения',
		dataField: 'statusSip',
		filter: selectFilter({
			placeholder: 'все',
			options: {
				зарегистрирован: 'зарегистрирован',
				незарегистрирован: 'незарегистрирован',
				регистрация: 'регистрация',
				отключен: 'отключен',
				'неопределенное состояние': 'неопределенное состояние',
			},
		}),
		sort: true,
	},
	{
		text: 'Сервисы',
		dataField: 'services',
	},
	{
		text: 'Тарифы',
		dataField: 'tariff',
	},
	{
		text: 'Телефон',
		dataField: 'phone',
		sort: true,
	},
	{
		text: 'Провайдер',
		dataField: 'server_sip',
		sort: true,
	},
	{
		text: 'Баланс',
		dataField: 'balance',
	},
	{
		text: 'Задача',
		dataField: 'task',
		filter: selectFilter({
			placeholder: 'все',
			options: {
				'прием показаний': 'прием показаний',
				'автообзвон должников': 'автообзвон должников',
				'подтверждение заказа': 'подтверждение заказа',
			},
		}),
		sort: true,
	},
	{
		text: 'Время создания',
		dataField: 'time',
		sort: true,
	},
	{
		text: 'Марс ID',
		dataField: 'marsID',
	},
	{
		text: 'Количество линий',
		dataField: 'lines',
		sort: true,
	},
];

export const columnsUsers = [
	{
		text: 'ИНН',
		dataField: 'login',
		sort: true,
	},
	{
		text: 'Имя',
		dataField: 'name',
		sort: true,
	},
];

export const columnsBalances = [
	{
		text: 'Дата',
		dataField: 'date',
		sort: true,
	},
	{
		text: 'Сервис',
		dataField: 'service',
		filter: selectFilter({
			placeholder: 'все',
			options: {
				getter: 'прием показаний',
				autocall: 'автообзвон должников',
				shop: 'обработка заказов',
			},
		}),
		sort: true,
	},
	{
		text: 'Тип операции',
		dataField: 'operations',
		filter: selectFilter({
			placeholder: 'все',
			options: {
				delete: 'Списание',
				add: 'Пополнение',
				add_trial: 'Пополнение(тестовое)',
			},
		}),
		sort: true,
	},
	{
		text: 'Значение',
		dataField: 'value',
		sort: true,
	},
];
