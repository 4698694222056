export const URL_API_MONITOR_SERVER = window._env_
	? window._env_.URL_API_SERVER
	: 'http://localhost:3003';
export const URL_API_MONITOR_WEB_SOCKET_SERVER = window._env_
	? window._env_.URL_WS_SERVER
	: 'ws://localhost:3003';
export const URL_SPEECH = window._env_
	? window._env_.URL_SPEECH
	: 'https://speech.etcd.kloud.one/api/';
export const URL_LK_SERVER = window._env_
	? window._env_.URL_LK_SERVER[window._env_.URL_LK_SERVER.length - 1] === '/'
		? window._env_.URL_LK_SERVER
		: `${window._env_.URL_LK_SERVER}/`
	: 'http://localhost:8444/';
