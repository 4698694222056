import React from 'react';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import { RangeDatePicker } from 'react-google-flight-datepicker';
import 'react-google-flight-datepicker/dist/main.css';
import { func, bool } from 'prop-types';
import { Button } from 'react-bootstrap';
import './style.css';
import SearchInput from '../../inputs/searchInput';
import { getUsers } from '../../../api/users';
import TableBalances from '../../tableBalances';
import ConfirmToLink from '../confirmToLink';

const ReportsUsersModal = ({ show, close }) => {
	const [initUsers, setInitUsers] = React.useState([]);
	const [users, setUsers] = React.useState([]);
	const [selectedUser, setSelectedUser] = React.useState({});
	const [searchValue, setSearchValue] = React.useState('');
	const startDateValue = moment()
		.set('date', 1)
		.set({ hour: 0, minute: 0, second: 0 })
		.toDate();
	const [startDate, setStartDate] = React.useState(
		moment(startDateValue).format('YYYY.MM.DD HH:mm:ss')
	);
	const [endDate, setEndDate] = React.useState(
		moment(new Date()).format('YYYY.MM.DD HH:mm:ss')
	);
	const [showConfirm, setShowConfirm] = React.useState(false);

	const getServices = () => {
		const services = [];
		if (
			selectedUser.payment_type &&
			selectedUser.payment_type.includes('answer')
		)
			services.push('Прием показаний');
		if (
			selectedUser.payment_type &&
			selectedUser.payment_type.includes('calls')
		)
			services.push('Обзвон должников');
		if (selectedUser.payment_type_shop) services.push('Подтверждение заказов');
		return services;
	};

	const changeDate = (startDate, endDate) => {
		if (startDate !== null && endDate !== null) {
			const newStartDate = moment(startDate).format('YYYY.MM.DD HH:mm:ss');
			const newEndDate = `${
				moment(endDate).format('YYYY.MM.DD HH:mm:ss').split(' ')[0]
			} 23:59:59`;
			setStartDate(newStartDate);
			setEndDate(newEndDate);
		}
	};

	const changeSearchValue = (value) => {
		setSearchValue(value);
		if (value.length) {
			setUsers(
				initUsers.filter((user) =>
					user.login.toLowerCase().includes(value.toLowerCase())
				)
			);
		} else {
			setUsers(initUsers);
		}
	};

	const changeSelectedUser = (id) => {
		setSelectedUser(users.find((item) => item.clientID === id));
	};

	const openConfirmToLink = () => {
		setShowConfirm(true);
		// window.open(`${URL_LK_SERVER}auth/local?login=avi@digt.ru&password=qwerty123&service=counter`, '_blank');
	};

	const closeConfirmToLink = () => {
		setShowConfirm(false);
	};

	React.useEffect(() => {
		getUsers().then((res) => {
			setUsers(res.data);
			setInitUsers(res.data);
		});
	}, [selectedUser]);

	return (
		<div>
			<Modal
				show={show}
				onHide={close}
				fullscreen={true}
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<div className={'reports_users_modal__wrapper'}>
					<Modal.Header closeButton>
						<h5>Отчеты по пользователям</h5>
						<div className="table_date_picker_wrapper">
							<RangeDatePicker
								startDate={startDate}
								endDate={endDate}
								minDate={new Date(2020, 0, 1)}
								maxDate={new Date()}
								onChange={changeDate}
								dateFormat="DD.MM.YYYY"
								monthFormat="MMM YYYY"
								startDatePlaceholder="Начало"
								endDatePlaceholder="Конец"
								disabled={false}
								startWeekDay="monday"
							/>
						</div>
						<div className={'balances_user'}>
							{selectedUser.payment_type &&
							selectedUser.payment_type.includes('answer') ? (
								<div className={'balances_user__service'}>
									<span className={'balances_user__service_name'}>
										Прием показаний
									</span>
									<span>
										{selectedUser.tariff === undefined
											? `Тестовый ${Math.floor(
													selectedUser.balance_getter_test / 60
											  )} мин.`
											: selectedUser.tariff === 'basic'
											? `Базовый ${Math.floor(
													selectedUser.balance_getter_basic / 60
											  )} мин.`
											: selectedUser.tariff === 'advanced'
											? `Расширенный ${Math.floor(
													selectedUser.balance_getter_advanced / 60
											  )} мин.`
											: ''}
									</span>
								</div>
							) : null}
							{selectedUser.payment_type &&
							selectedUser.payment_type.includes('calls') ? (
								<div className={'balances_user__service'}>
									<span className={'balances_user__service_name'}>
										Обзвон должников
									</span>
									<span>{`Баланс ${selectedUser.balance_autocall}`}</span>
								</div>
							) : null}
							{selectedUser.payment_type_shop ? (
								<div className={'balances_user__service'}>
									<span className={'balances_user__service_name'}>
										Подтверждение заказов
									</span>
									<span>{`Баланс ${selectedUser.balance_shop}`}</span>
								</div>
							) : null}
						</div>
						<div className={'balances_user__service'}>
							<span className={'balances_user__service_name'}>Email</span>
							<span>{selectedUser.email}</span>
						</div>
						<div className={'balances_user__profile'}>
							<Button
								className={'balances_user__profile__button'}
								onClick={openConfirmToLink}
							>
								Профиль
							</Button>
						</div>
					</Modal.Header>
					<Modal.Body>
						<div className={'reports_users_modal'}>
							<div className={'reports_users_modal__users'}>
								<div className={'reports_users_modal__users__search'}>
									<SearchInput
										value={searchValue}
										onChange={changeSearchValue}
									/>
								</div>
								<div className={'reports_users_modal__users__list'}>
									<ul>
										{users.map((user) => (
											<li key={user.clientID}>
												<div
													className={
														selectedUser.clientID === user.clientID
															? 'reports_users_modal__users__list_item_selected reports_users_modal__users__list_item'
															: 'reports_users_modal__users__list_item'
													}
													onClick={() => changeSelectedUser(user.clientID)}
												>
													{user.login}
												</div>
											</li>
										))}
									</ul>
								</div>
							</div>
							<div className={'reports_users_modal__table'}>
								<TableBalances
									clientID={selectedUser.clientID}
									startDate={String(startDate)}
									endDate={String(endDate)}
									context={'zhkh'}
								/>
							</div>
						</div>
					</Modal.Body>
				</div>
			</Modal>
			<ConfirmToLink
				show={showConfirm}
				close={closeConfirmToLink}
				services={getServices()}
				user={selectedUser}
			/>
		</div>
	);
};

ReportsUsersModal.propTypes = {
	show: bool,
	close: func,
};

export default ReportsUsersModal;
