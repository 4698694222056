import axios from 'axios';
import { URL_API_MONITOR_SERVER } from '../utils/constants';

export const getUsers = () => {
	const resData = axios.get(`${URL_API_MONITOR_SERVER}/api/v1/users`, {
		withCredentials: true,
	});
	resData.catch((err) => {
		console.log(err.message);
	});
	return resData;
};

export const getCrmToolsUsers = () => {
	const resData = axios.get(`${URL_API_MONITOR_SERVER}/getCrmToolsUsers`, {
		withCredentials: true,
	});
	resData.catch((err) => {
		console.log(err.message);
	});
	return resData;
};
