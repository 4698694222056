import React from 'react';
import { Form } from 'react-bootstrap';
import { elementType } from 'prop-types';
import 'bootstrap/dist/css/bootstrap.min.css';
import './style.css';

const InputPassword = (props) => {
	const { passwordRef } = props;
	const [type, setType] = React.useState('password');
	const inputEl = React.useRef(null);

	const onChangeShow = () => {
		const icon = document.querySelector('.form-control_btn_show_pass');
		if (icon) {
			setType(type === 'password' ? 'text' : 'password');
			inputEl.current.classList.toggle('no-view');
			inputEl.current.classList.toggle('view');
		}
	};

	return (
		<div className="password_field">
			<Form.Control
				type={type}
				placeholder="Введите пароль"
				ref={passwordRef}
			/>
			<span
				className="form-control_btn_show_pass view"
				onClick={onChangeShow}
				ref={inputEl}
			></span>
		</div>
	);
};

export default InputPassword;

InputPassword.propTypes = {
	passwordRef: elementType,
};
