import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { columnsUsers } from '../../utils/columns';
import { array } from 'prop-types';

const TableUsers = ({ data }) => {
	const customTotal = (from, to, size) => (
		<span>
			Просмотр от {from} до {to} из {size}
		</span>
	);

	const pagination = paginationFactory({
		totalSize: data.length,
		lastPageText: '>>',
		firstPageText: '<<',
		nextPageText: '>',
		prePageText: '<',
		showTotal: true,
		alwaysShowAllBtns: true,
		paginationTotalRenderer: customTotal,
		firstPageTitle: 'Первая страница',
		prePageTitle: 'Предыдущая страница',
		nextPageTitle: 'Следующая страница',
		lastPageTitle: 'Последняя страница',
		sizePerPageList: [
			{
				text: '5',
				value: 5,
			},
			{
				text: '10',
				value: 10,
			},
			{
				text: '15',
				value: 15,
			},
			{
				text: '20',
				value: 20,
			},
		],
	});

	return (
		<div>
			<BootstrapTable
				keyField="_id"
				data={data}
				columns={columnsUsers}
				bootstrap4
				pagination={pagination}
			/>
		</div>
	);
};
TableUsers.propTypes = {
	data: array,
};

export default TableUsers;
