import React from 'react';
import Table from '../table/index';
import { func } from 'prop-types';

const Main = ({ setSession }) => {
	return (
		<div>
			<h1>Система мониторинга</h1>
			<Table setSession={setSession} />
		</div>
	);
};

Main.propTypes = {
	setSession: func,
};

export default React.memo(Main);
