import React from 'react';
import Main from '../../components/main/index';
import Header from '../../components/header/index';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect,
} from 'react-router-dom';
import LoginForm from '../../components/loginForm/index';
import './App.css';
import { bool, array } from 'prop-types';

PrivateRoute.propTypes = {
	auth: bool,
	children: array,
};

function PrivateRoute({ auth, children, ...rest }) {
	return (
		<Route
			{...rest}
			render={() => {
				return auth ? children : <Redirect to="/login" />;
			}}
		/>
	);
}

function App() {
	const [session, setSession] = React.useState(true);
	return (
		<div className="App">
			<Router>
				<Switch>
					<Route path="/login">
						<LoginForm redirectSuccsess="/" setSession={setSession} />
					</Route>
					<PrivateRoute path="/" auth={session}>
						<Header setSession={setSession} />
						<Main setSession={setSession} />
					</PrivateRoute>
				</Switch>
			</Router>
		</div>
	);
}

export default App;
