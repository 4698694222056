import React from 'react';
import Modal from 'react-bootstrap/Modal';
import TableUsers from '../../tableUsers/index';
import { RangeDatePicker } from 'react-google-flight-datepicker';
import moment from 'moment';
import 'react-google-flight-datepicker/dist/main.css';
import axios from 'axios';
import { URL_API_MONITOR_SERVER } from '../../../utils/constants';
import { func, bool } from 'prop-types';
import './style.css';

const DisactiveUsersModal = ({ show, close }) => {
	const [users, setUsers] = React.useState([]);

	const changeDate = (startDate, endDate) => {
		if (startDate !== null && endDate !== null) {
			const newStartDate = moment(startDate).format('YYYY.MM.DD HH:mm:ss');
			const newEndDate = `${
				moment(endDate).format('YYYY.MM.DD HH:mm:ss').split(' ')[0]
			} 23:59:59`;
			getUsers(newStartDate, newEndDate);
		}
	};

	const getUsers = (start, end) => {
		(async () => {
			try {
				await axios
					.get(
						`${URL_API_MONITOR_SERVER}/getDisactiveUsers?start=${start}&end=${end}`,
						{ withCredentials: true }
					)
					.then((res) => {
						setUsers(res.data);
					})
					.catch((err) => {
						console.log(err.message);
					});
			} catch (error) {
				console.log(error.message);
			}
		})();
	};

	return (
		<div>
			<Modal
				show={show}
				onHide={close}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton>
					<h5>Список неактивных пользователей</h5>
				</Modal.Header>
				<Modal.Body>
					<div className="table_date_picker_wrapper">
						<RangeDatePicker
							minDate={new Date(2020, 0, 1)}
							maxDate={new Date()}
							onChange={changeDate}
							dateFormat="DD.MM.YYYY"
							monthFormat="MMM YYYY"
							startDatePlaceholder="Начало"
							endDatePlaceholder="Конец"
							disabled={false}
							startWeekDay="monday"
						/>
					</div>
					<TableUsers data={users} />
				</Modal.Body>
			</Modal>
		</div>
	);
};

DisactiveUsersModal.propTypes = {
	show: bool,
	close: func,
};

export default DisactiveUsersModal;
