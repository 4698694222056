/* eslint-disable react/prop-types */
import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import LoadingOverlay from 'react-loading-overlay';
import { func } from 'prop-types';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import filterFactory from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import { columns } from '../../utils/columns';
import { URL_API_MONITOR_WEB_SOCKET_SERVER } from '../../utils/constants';
import StatusSip from '../icons/statusSip/index';
import BalanceButton from '../buttons/balance/index';
import UpdateButton from '../buttons/updateData/index';
import DisactiveUsersButton from '../buttons/disactiveUsers/index';
import CopyMarsButton from '../buttons/copyMarsId';
import MetersButton from '../buttons/meters';
import { getTasksMars } from '../../api/tasksMars';
import { getUsers } from '../../api/users';
import Services from '../buttons/services';
import Tariff from '../buttons/tariff';
import ReportsUsersButton from '../buttons/reportsUsers';
import RestartMarsButton from '../buttons/restartMars';
import CrmToolsUsersButton from '../buttons/crmtoolsUsers';

const socket = new WebSocket(URL_API_MONITOR_WEB_SOCKET_SERVER);

const Table = ({ setSession }) => {
	const [state, setState] = React.useState([]);
	// const [users, setUsers] = React.useState([]);
	let users = [];
	const [settings, setSettings] = React.useState({
		page: 1,
		sizePerPage: 5,
		filter: {},
		sort: {},
		searchText: '',
	});

	const [totalSize, setTotalSize] = React.useState(null);
	const [loading, setLoading] = React.useState(false);

	const { SearchBar, ClearSearchButton } = Search;

	const customTotal = (from, to, size) => (
		<span>
			Просмотр от {from} до {to} из {size}
		</span>
	);

	const pagination = paginationFactory({
		page: settings.page,
		sizePerPage: settings.sizePerPage,
		totalSize: totalSize,
		lastPageText: '>>',
		firstPageText: '<<',
		nextPageText: '>',
		prePageText: '<',
		showTotal: true,
		alwaysShowAllBtns: true,
		paginationTotalRenderer: customTotal,
		firstPageTitle: 'Первая страница',
		prePageTitle: 'Предыдущая страница',
		nextPageTitle: 'Следующая страница',
		lastPageTitle: 'Последняя страница',
		sizePerPageList: [
			{
				text: '5',
				value: 5,
			},
			{
				text: '10',
				value: 10,
			},
			{
				text: '15',
				value: 15,
			},
			{
				text: '20',
				value: 20,
			},
		],
	});

	const onTableChange = (type, newTable) => {
		let newPage = newTable.page;
		if (settings.searchText !== newTable.searchText) newPage = 1;
		setSettings({
			page: newPage,
			sizePerPage: newTable.sizePerPage,
			sort: {
				sortField: newTable.sortField,
				sortOrder: newTable.sortOrder,
			},
			filter: newTable.filters,
			searchText: newTable.searchText || '',
		});
	};

	const getServices = (user) => {
		if (user.payment_type && user.payment_type_shop) {
			return `${user.payment_type}_${user.payment_type_shop}`;
		} else if (user.payment_type) {
			return user.payment_type;
		} else if (user.payment_type_shop) {
			return user.payment_type_shop;
		} else return '';
	};

	const handleData = async (data) => {
		if (!data?.length) return [];
		return data.map((item) => {
			const user = users.find((user) => user.clientID === item.clientID);
			const obj = {};
			obj._id = item._id;
			obj.login = item.login;
			obj.statusSip = (
				<StatusSip status={item.statusSip} time={item.timeUpdateSip} />
			);
			obj.services = <Services services={getServices(user)} />;
			obj.tariff = (
				<Tariff
					user={user}
					service={
						item.task
							? item.task.includes('getter')
								? 'getter'
								: item.task.includes('autocall')
								? 'autocall'
								: item.task.includes('shop')
								? 'shop'
								: ''
							: ''
					}
				/>
			);
			obj.phone = item.phone;
			obj.balance = (
				<BalanceButton
					login={item.login}
					phone={item.phone}
					password={item.phone_pass}
					service={item.server_sip}
				/>
			);
			obj.server_sip = item.server_sip;
			obj.task = item.task
				? item.task.includes('getter')
					? 'прием показаний'
					: item.task.includes('autocall')
					? 'автообзвон должников'
					: item.task.includes('shop')
					? 'подтверждение заказа'
					: ''
				: '';
			obj.time = item.time;
			obj.lines = item.lines;
			obj.marsID = <CopyMarsButton id={item.marsID} />;
			// console.log(obj);
			return obj;
		});
	};

	const sendRequest = () => {
		setLoading(true);
		getTasksMars(settings)
			.then((res) => {
				setTotalSize(Number(res.headers['x-total-count']));
				handleData(res.data).then((r) => {
					setState(r);
				});
			})
			.catch((err) => {
				if (err.message.includes('401')) {
					console.log(err.message);
					setSession(false);
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	React.useEffect(() => {
		if (users.length) {
			sendRequest();
		} else {
			getUsers()
				.then((result) => {
					users = result.data;
					sendRequest();
				})
				.catch((err) => {
					if (err.message.includes('401')) {
						console.log(err.message);
						setSession(false);
					}
				});
		}
		socket.onmessage = (event) => {
			if (event.data === 'changeStream') {
				sendRequest();
			}
		};
	}, [settings.page, settings.sizePerPage, settings.sort, settings.filter]);

	return (
		<div className="table">
			<ToolkitProvider
				keyField="_id"
				data={state}
				columns={columns}
				bootstrap4
				search
			>
				{(props) => (
					<div>
						<div className="settings-bar">
							<div className="settings-bar__left">
								<DisactiveUsersButton />
								<MetersButton />
								<ReportsUsersButton />
								<RestartMarsButton />
								<CrmToolsUsersButton />
							</div>
							<div className="settings-bar__right">
								<SearchBar {...props.searchProps} placeholder={'Поиск'} />
								<span className="settings-bar__right__search_label">
									телефон/логин/провайдер
								</span>
								<ClearSearchButton {...props.searchProps} text="Очистить" />
								<UpdateButton onClick={sendRequest} />
							</div>
						</div>
						<LoadingOverlay active={loading} spinner text="Загрузка данных...">
							<BootstrapTable
								remote
								{...props.baseProps}
								pagination={pagination}
								filter={filterFactory()}
								defaultSorted={[{ dataField: 'time', order: 'desc' }]}
								onTableChange={onTableChange}
							/>
						</LoadingOverlay>
					</div>
				)}
			</ToolkitProvider>
		</div>
	);
};

export default React.memo(Table);

Table.propTypes = {
	setSession: func,
};
