import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import CrmToolsUsersModal from '../../modalWindows/CrmtoolsUsers';

const CrmToolsUsersButton = () => {
	const [showModal, setShowModal] = React.useState(false);

	const onClick = () => {
		setShowModal(true);
	};

	return (
		<div>
			<OverlayTrigger
				placement="bottom"
				overlay={<Tooltip id="button-tooltip-2">Пользователи CrmTools</Tooltip>}
			>
				{({ ...triggerHandler }) => (
					<Button {...triggerHandler} onClick={onClick}>
						<svg
							width="16"
							height="16"
							fill="currentColor"
							className="bi bi-camera-reels"
							viewBox="0 0 16 16"
						>
							<path d="M6 3a3 3 0 1 1-6 0 3 3 0 0 1 6 0M1 3a2 2 0 1 0 4 0 2 2 0 0 0-4 0" />
							<path d="M9 6h.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 7.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 16H2a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2zm6 8.73V7.27l-3.5 1.555v4.35l3.5 1.556zM1 8v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1" />
							<path d="M9 6a3 3 0 1 0 0-6 3 3 0 0 0 0 6M7 3a2 2 0 1 1 4 0 2 2 0 0 1-4 0" />
						</svg>
					</Button>
				)}
			</OverlayTrigger>
			<CrmToolsUsersModal show={showModal} close={() => setShowModal(false)} />
		</div>
	);
};

export default CrmToolsUsersButton;
