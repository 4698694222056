import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DisactiveUsersModal from '../../modalWindows/disactiveUsers/index';
import 'bootstrap/dist/css/bootstrap.min.css';

const DisactiveUsersButton = () => {
	const [showModal, setShowModal] = React.useState(false);

	const onClick = () => {
		setShowModal(true);
	};

	return (
		<div>
			<OverlayTrigger
				placement="bottom"
				overlay={
					<Tooltip id="button-tooltip-2">
						Получить список неактивных пользователей
					</Tooltip>
				}
			>
				{({ ...triggerHandler }) => (
					<Button {...triggerHandler} onClick={onClick}>
						<svg
							width="20"
							height="20"
							fill="currentColor"
							className="bi bi-people-fill"
							viewBox="0 0 16 16"
						>
							<path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
							<path
								fillRule="evenodd"
								d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"
							/>
							<path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
						</svg>
					</Button>
				)}
			</OverlayTrigger>
			<DisactiveUsersModal show={showModal} close={() => setShowModal(false)} />
		</div>
	);
};

export default DisactiveUsersButton;
