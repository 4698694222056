import axios from 'axios';
import { URL_API_MONITOR_SERVER } from '../utils/constants';

const createRequest = (settings) => {
	let query = '';
	if (settings.filter && Object.keys(settings.filter).length) {
		const filteredFields = Object.keys(settings.filter);
		const arrQuery = filteredFields.map((item, i) => {
			const wordRegex = settings.filter[filteredFields[i]].filterVal;
			if (item !== 'task') return `{"${filteredFields[i]}":"${wordRegex}"}`;
			else {
				return `{"task":{"$regex": "${
					wordRegex === 'прием показаний'
						? 'getter'
						: wordRegex === 'автообзвон должников'
						? 'autocall'
						: wordRegex === 'подтверждение заказа'
						? 'shop'
						: ''
				}"}}`;
			}
		});
		query = `"$and":[${arrQuery.join(',')}]`;
	}
	if (settings.searchText.length) {
		if (query.length) {
			query = `"$and":[{${query}},{"$or":[{"login":{"$regex":"${settings.searchText}", "$options": "i"}},{"phone":{"$regex":"${settings.searchText}", "$options": "i"}},{"server_sip":{"$regex":"${settings.searchText}", "$options": "i"}}]}]`;
		} else {
			query = `"$or":[{"login":{"$regex":"${settings.searchText}", "$options": "i"}},{"phone":{"$regex":"${settings.searchText}", "$options": "i"}},{"server_sip":{"$regex":"${settings.searchText}", "$options": "i"}}]`;
		}
	}
	let sort;
	if (
		!Object.keys(settings.sort).length ||
		(settings.sort.sortField === null && settings.sort.sortOrder === undefined)
	) {
		sort = '';
	} else {
		sort =
			settings.sort.sortOrder === 'asc'
				? `"${settings.sort.sortField}": 1`
				: `"${settings.sort.sortField}":-1`;
	}
	const limit = settings.sizePerPage;
	const skip = (settings.page - 1) * settings.sizePerPage;
	return { limit, skip, sort, query };
};

export const getTasksMars = (settings) => {
	const { limit, skip, sort, query } = createRequest(settings);
	const resData = axios.get(
		`${URL_API_MONITOR_SERVER}/api/v1/tasks.mars?limit=${limit}&skip=${skip}&sort={${sort}}&query={${query}}`,
		{ withCredentials: true }
	);
	resData.catch((err) => {
		console.log(err.message);
	});
	return resData;
};
