import React from 'react';
import { Form, Button } from 'react-bootstrap';
import InputPassword from '../inputs/password/index';
import { withRouter } from 'react-router-dom';
import { URL_API_MONITOR_SERVER } from '../../utils/constants';
import axios from 'axios';
import CustomSnackBar from '../customSnackbar/index';
import './style.css';

const LoginForm = withRouter(({ history, ...props }) => {
	let login, password, button;
	const { redirectSuccsess, setSession } = props;
	const [alertMessage, setAlertMessage] = React.useState({
		open: false,
		message: null,
		success: null,
	});

	const snackBar = React.useRef(null);

	const onCloseAlertMessage = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setAlertMessage({
			...alertMessage,
			open: false,
		});
		if (snackBar.current) snackBar.current.style.display = 'none';
	};

	const onSubmit = () => {
		(async () => {
			const body = {
				login: login.value,
				password: password.value,
			};
			await axios
				.post(`${URL_API_MONITOR_SERVER}/login`, body, {
					withCredentials: true,
				})
				.then((res) => {
					if (res.data.success === false) alert(res.data.msg);
					else if (res.status === 200) {
						setSession(true);
						history.push(redirectSuccsess);
					}
				})
				.catch((err) => {
					console.log(err.message);
					if (err.message.includes('401')) {
						if (snackBar.current) snackBar.current.style.display = 'flex';
						setAlertMessage({
							open: true,
							message: 'Неправильные имя пользователя или пароль',
							success: 'error',
						});
					}
				});
		})();
	};

	const checkForm = () => {
		if (login) {
			if (login.value && password.value) {
				button.classList.remove('disable');
				button.tabIndex = '0';
			} else {
				button.classList.add('disable');
				button.tabIndex = '-1';
			}
		}
	};

	return (
		<div className="loginForm__container">
			<Form className="loginForm" onChange={checkForm}>
				<Form.Group className="loginForm__input">
					<Form.Label>Логин</Form.Label>
					<Form.Control type="text" ref={(n) => (login = n)}></Form.Control>
				</Form.Group>
				<Form.Group className="loginForm__input">
					<Form.Label>Пароль</Form.Label>
					<InputPassword passwordRef={(n) => (password = n)}></InputPassword>
				</Form.Group>
				<Button
					className="loginForm__button disable"
					variant="outline-primary"
					onClick={onSubmit}
					ref={(n) => (button = n)}
					tabIndex="-1"
				>
					Войти
				</Button>
			</Form>
			<CustomSnackBar
				open={alertMessage.open}
				close={onCloseAlertMessage}
				severity={alertMessage.success}
				message={alertMessage.message}
				refSnackBar={snackBar}
			/>
		</div>
	);
});

export default LoginForm;
