import React from 'react';
import { string } from 'prop-types';
import ServiceIcon from '../../icons/serviceIcon';
import './style.css';

const Services = (props) => {
	const { services } = props;
	const serviceArray = services.split('_');
	return (
		<div className={'services'}>
			{serviceArray.map((item) => (
				<ServiceIcon serviceName={item} key={item} />
			))}
		</div>
	);
};

Services.propTypes = {
	services: string,
};

export default Services;
