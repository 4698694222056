import React from 'react';
import { Button, OverlayTrigger, Tooltip, Modal } from 'react-bootstrap';
import axios from 'axios';
import { URL_API_MONITOR_SERVER } from '../../../utils/constants';
import 'bootstrap/dist/css/bootstrap.min.css';

const RestartMarsButton = () => {
	const [message, setMessage] = React.useState(0);
	const [show, setShow] = React.useState(false);
	const [reload, setReload] = React.useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => {
		setMessage(null);
		setShow(true);
	};

	const restartMars = async () => {
		setReload(true);
		setMessage('Перезагружаем...');

		axios
			.get(`${URL_API_MONITOR_SERVER}/restartMars`, {
				withCredentials: true,
			})
			.then((res) => {
				setReload(false);

				if (res.data.success) {
					setMessage(
						`Перезагрузка марсов прошла успешно. Кол-во: ${res.data.total || 0}`
					);
				} else {
					setMessage(`Перезагрузка марсов прошла неуспешно ${res.data?.err}`);
				}
			})
			.catch((err) => {
				console.error(err);
				setReload(false);
				setMessage('Сервис недоступен или ошибка в данных запроса!');
			});
	};

	return (
		<>
			<OverlayTrigger
				placement="bottom"
				overlay={<Tooltip id="button-tooltip-2">Перезагрука марсов</Tooltip>}
			>
				{({ ...triggerHandler }) => (
					<Button {...triggerHandler} onClick={handleShow}>
						<svg
							width="20"
							height="20"
							fill="currentColor"
							className="bi bi-arrow-clockwise"
							viewBox="0 0 16 16"
						>
							<path d="M1.161 8a6.84 6.84 0 1 0 6.842-6.84.58.58 0 1 1 0-1.16 8 8 0 1 1-6.556 3.412l-.663-.577a.58.58 0 0 1 .227-.997l2.52-.69a.58.58 0 0 1 .728.633l-.332 2.592a.58.58 0 0 1-.956.364l-.643-.56A6.812 6.812 0 0 0 1.16 8z" />
							<path d="M6.641 11.671V8.843h1.57l1.498 2.828h1.314L9.377 8.665c.897-.3 1.427-1.106 1.427-2.1 0-1.37-.943-2.246-2.456-2.246H5.5v7.352h1.141zm0-3.75V5.277h1.57c.881 0 1.416.499 1.416 1.32 0 .84-.504 1.324-1.386 1.324h-1.6z" />
						</svg>
					</Button>
				)}
			</OverlayTrigger>

			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					<Modal.Title>Перезагрузка марсов</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{message
						? message
						: 'Нажмите перезагрузить, чтобы перезагрузить марсы!'}
				</Modal.Body>
				<Modal.Footer>
					{reload ? null : (
						<div>
							<Button variant="secondary" onClick={handleClose}>
								Закрыть
							</Button>
							{message ? null : (
								<Button variant="primary" onClick={restartMars}>
									Перезагрузить
								</Button>
							)}
						</div>
					)}
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default RestartMarsButton;
