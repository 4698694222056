import React from 'react';
import { Button, Toast } from 'react-bootstrap';
import { string } from 'prop-types';
import axios from 'axios';
import { URL_API_MONITOR_SERVER } from '../../../utils/constants';
import './style.css';

const BalanceButton = ({ login, phone, password, service }) => {
	const [showToast, setShowToast] = React.useState(false);
	const [message, setMessage] = React.useState(0);
	const body = {
		phone: phone,
		password: password,
		service: service,
	};

	const getBalance = async () => {
		axios
			.post(`${URL_API_MONITOR_SERVER}/getBalance`, body, {
				withCredentials: true,
			})
			.then((res) => {
				if (res.data.balance) {
					setMessage(`Баланс пользователя ИНН ${login}:  ${res.data.balance}`);
					setShowToast(true);
				} else if (res.data.message) {
					if (res.data.message === 'Invalid service') {
						setMessage(
							`Сервис: ${service} не предоставляет возможность проверки баланса`
						);
						setShowToast(true);
					} else if (res.data.message === 'No data received') {
						setMessage(`Данные не получены. Ошибка учетных данных`);
						setShowToast(true);
					} else if (res.data.message === 'You are banned! Wait 24 hours.') {
						setMessage(`Сервис заблокировал Вас на 24 часа`);
						setShowToast(true);
					} else {
						setMessage(res.data.message);
						setShowToast(true);
					}
				}
			})
			.catch((err) => {
				console.log(err);
				setMessage('Сервис недоступен или ошибка в данных запроса!');
				setShowToast(true);
			});
	};

	return (
		<div className="button-balance">
			<Toast
				show={showToast}
				onClose={() => setShowToast(false)}
				className="button-balance__toast"
			>
				<Toast.Header className="button-balance__toast__header">
					<strong className="mr-auto">{message}</strong>
				</Toast.Header>
			</Toast>
			<Button onClick={getBalance}>Узнать баланс</Button>
		</div>
	);
};

BalanceButton.propTypes = {
	login: string,
	phone: string,
	password: string,
	service: string,
};

export default BalanceButton;
