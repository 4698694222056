import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import axios from 'axios';
import { URL_API_MONITOR_SERVER } from '../../../utils/constants';
import 'bootstrap/dist/css/bootstrap.min.css';
import MetersListModal from '../../modalWindows/MetersList';

const MetersButton = () => {
	const [showModal, setShowModal] = React.useState(false);
	const [metersList, setMetersList] = React.useState([]);
	const onClick = () => {
		(async () => {
			try {
				await axios
					.get(
						`${URL_API_MONITOR_SERVER}/api/v1/meters?query={"from_phone":true}&sort={"date":-1}&limit=10&skip=0`,
						{ withCredentials: true }
					)
					.then((res) => {
						setMetersList(res.data);
						setShowModal(true);
					})
					.catch((err) => {
						console.log(err.message);
					});
			} catch (error) {
				console.log(error.message);
			}
		})();
	};
	return (
		<div>
			<OverlayTrigger
				placement="bottom"
				overlay={
					<Tooltip id="button-tooltip-2">
						Получить последние 10 записей о приеме показаний
					</Tooltip>
				}
			>
				{({ ...triggerHandler }) => (
					<Button {...triggerHandler} onClick={onClick}>
						<svg
							width="20"
							height="20"
							fill="currentColor"
							className="bi bi-telephone-outbound-fill"
							viewBox="0 0 16 16"
						>
							<path
								fillRule="evenodd"
								d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zM11 .5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-4.146 4.147a.5.5 0 0 1-.708-.708L14.293 1H11.5a.5.5 0 0 1-.5-.5z"
							/>
						</svg>
					</Button>
				)}
			</OverlayTrigger>
			<MetersListModal
				show={showModal}
				close={() => setShowModal(false)}
				list={metersList}
			/>
		</div>
	);
};

export default MetersButton;
