import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { func, bool, array, object } from 'prop-types';
import { Button } from 'react-bootstrap';
import { URL_LK_SERVER } from '../../../utils/constants';
import './style.css';

const ConfirmToLink = ({ show, close, services, user }) => {
	const getService = (name) => {
		return {
			['Прием показаний']: 'counter',
			['Обзвон должников']: 'obzvon',
			['Подтверждение заказов']: 'shop',
		}[name];
	};

	const getPass = (name) => {
		return {
			['Прием показаний']: atob(user.encryptedPassword_getter || ''),
			['Обзвон должников']: atob(user.encryptedPassword_autocall || ''),
			['Подтверждение заказов']: atob(user.encryptedPassword_shop || ''),
		}[name];
	};

	const onLink = (name) => {
		window.open(
			`${URL_LK_SERVER}auth/local?login=${user.login}&password=${getPass(
				name
			)}&service=${getService(name)}`,
			'_blank'
		);
	};

	return (
		<div>
			<Modal
				show={show}
				onHide={close}
				aria-labelledby="contained-modal-title-vcenter"
				centered
				className={'confirm_modal'}
			>
				<div className={'confirm_to_link_modal_wrapper'}>
					<Modal.Header closeButton>
						<h5>Переход в личный кабинет</h5>
					</Modal.Header>
					<Modal.Body>
						<div className={'confirm_to_link_modal__buttons'}>
							{services
								? services.map((item) => (
										<Button key={item} onClick={() => onLink(item)}>
											{item}
										</Button>
								  ))
								: null}
						</div>
					</Modal.Body>
				</div>
			</Modal>
		</div>
	);
};

ConfirmToLink.propTypes = {
	show: bool,
	close: func,
	services: array,
	user: object,
};

export default ConfirmToLink;
