import axios from 'axios';
import moment from 'moment';
import { URL_API_MONITOR_SERVER } from '../utils/constants';

const createQuery = (props) => {
	let query, sort, limit, skip;
	const filterFields = Object.keys(props.filter);
	const arrQuery = [];
	if (filterFields.length || (props.startDate && props.endDate)) {
		filterFields.map((filter) => {
			if (filter === 'service') {
				arrQuery.push(`{"service":"${props.filter[filter].filterVal}"}`);
			} else if (filter === 'operations') {
				arrQuery.push(`{"operations":"${props.filter[filter].filterVal}"}`);
			}
			return false;
		});
		if (props.startDate && props.endDate) {
			arrQuery.push(
				`{"date": {"$gte":"${props.startDate}","$lte":"${props.endDate}"}}`
			);
		}
		query = `"$and":[{"clientID":"${props.clientID}"},${arrQuery.join(',')}]`;
	} else {
		query = `"clientID":"${props.clientID}"`;
	}
	limit = props.sizePerPage;
	skip = (props.page - 1) * props.sizePerPage;
	sort =
		props.sort.sortOrder === 'asc'
			? `${props.sort.sortField}`
			: `-${props.sort.sortField}`;
	return { query, sort, limit, skip };
};

export const getBalances = (props) => {
	const { query, sort, limit, skip } = createQuery(props);
	const resData = axios.get(
		`${URL_API_MONITOR_SERVER}/api/v1/${props.route}?skip=${skip}&limit=${limit}&sort=${sort}&query={${query}}`,
		{
			withCredentials: true,
		}
	);
	resData.catch((err) => {
		console.log(err.message);
	});
	return resData;
};

export const getSum = async (startDate, endDate, clientID, filter, route) => {
	const newEndDate = moment(endDate).format('YYYY.MM.DD HH:mm:ss');
	const operations = filter.operations.filterVal;
	const service = filter.service.filterVal;
	if (!filter.operations || !filter.service) {
		return [0, ''];
	}

	const res = await axios.get(
		`${URL_API_MONITOR_SERVER}/${route}?start=${startDate}&end=${newEndDate}&operations=${operations}&clientID=${clientID}&service=${service}`,
		{ withCredentials: true }
	);

	let showValue = res.data.length ? res.data[0].value : 0;
	let extensionValue = 'звон.';
	if (filter.service.filterVal === 'getter') {
		showValue = res.data.length ? Math.ceil(res.data[0].value / 60) : 0;
		extensionValue = 'мин.';
	}

	return [showValue, extensionValue];
};
