import React from 'react';
import { Snackbar, Slide } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { func, bool, string, object } from 'prop-types';

const CustomSnackBar = ({ open, close, severity, message, refSnackBar }) => {
	return (
		<Snackbar
			open={open}
			onClose={close}
			ref={refSnackBar}
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			TransitionComponent={(props) => <Slide {...props} direction="up" />}
		>
			<Alert
				severity={severity}
				variant="filled"
				sx={{ width: '100%' }}
				onClose={close}
			>
				{message}
			</Alert>
		</Snackbar>
	);
};

CustomSnackBar.propTypes = {
	open: bool,
	close: func,
	severity: string,
	message: string,
	refSnackBar: object,
};

export default CustomSnackBar;
