import React from 'react';
import { Button } from 'react-bootstrap';
import { URL_API_MONITOR_SERVER } from '../../utils/constants';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { func } from 'prop-types';
import './style.css';

const Header = ({ setSession }) => {
	const logOut = () => {
		(async () => {
			await axios
				.get(`${URL_API_MONITOR_SERVER}/logout`, {
					withCredentials: true,
				})
				.then(() => {
					setSession(false);
				})
				.catch((err) => {
					if (err.message.includes('401')) {
						console.log(err.message);
						setSession(false);
					}
				});
		})();
	};

	return (
		<div className="header">
			<Button onClick={logOut} className="header__button-logout">
				Выйти
			</Button>
		</div>
	);
};

export default Header;

Header.propTypes = {
	setSession: func,
};
