import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { string } from 'prop-types';

const StatusSip = (props) => {
	const { status } = props;
	const { time } = props;
	let color, icon;
	switch (status) {
		case 'зарегистрирован':
			color = 'green';
			icon = (
				<svg
					width="30"
					height="30"
					fill={color}
					className="bi bi-check-circle-fill"
					viewBox="0 0 16 16"
				>
					<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
				</svg>
			);
			break;
		case 'незарегистрирован':
			color = 'red';
			icon = (
				<svg
					width="30"
					height="30"
					fill={color}
					className="bi bi-dash-circle-fill"
					viewBox="0 0 16 16"
				>
					<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1h-7z" />
				</svg>
			);
			break;
		default:
			color = 'grey';
			icon = (
				<svg
					width="30"
					height="30"
					fill={color}
					className="bi bi-circle-fill"
					viewBox="0 0 16 16"
				>
					<circle cx="8" cy="8" r="8" />
				</svg>
			);
			break;
	}

	return (
		<div>
			<OverlayTrigger
				delay="500"
				placement="bottom"
				overlay={
					<Tooltip id="button-tooltip-2">
						<p>{status}</p>
						<span>{time}</span>
					</Tooltip>
				}
			>
				{icon}
			</OverlayTrigger>
		</div>
	);
};

StatusSip.propTypes = {
	status: string,
	time: string,
};

export default StatusSip;
