import React from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReportsUsersModal from '../../modalWindows/reportsUsers';

const ReportsUsersButton = () => {
	const [showModal, setShowModal] = React.useState(false);

	const onClick = () => {
		setShowModal(true);
	};

	return (
		<div>
			<OverlayTrigger
				placement="bottom"
				overlay={
					<Tooltip id="button-tooltip-2">Отчеты по пользователям</Tooltip>
				}
			>
				{({ ...triggerHandler }) => (
					<Button {...triggerHandler} onClick={onClick}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="16"
							height="16"
							fill="currentColor"
							className="bi bi-bar-chart-fill"
							viewBox="0 0 16 16"
						>
							<path d="M1 11a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-3zm5-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V7zm5-5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1V2z" />
						</svg>
					</Button>
				)}
			</OverlayTrigger>
			<ReportsUsersModal show={showModal} close={() => setShowModal(false)} />
		</div>
	);
};

export default ReportsUsersButton;
